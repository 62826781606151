<template>
  <portal-view>
    <portal-header>API Keys</portal-header>
    <portal-main>
      <!-- <portal-main> -->
      <!-- <connection-dropdown></connection-dropdown> -->
      <associated-keys></associated-keys>
      <!-- </portal-main> -->
    </portal-main>
  </portal-view>
</template>

<script>
// import ConnectionDropdown from "./ConnectionDropdown.vue";
import AssociatedKeys from "./AssociatedKeys.vue";

export default {
  components: {
    // ConnectionDropdown,
    AssociatedKeys,
  },
};
</script>

<style scoped>
/* .portal-main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacing-6);
  background-color: var(--primary-light-6);
  padding: var(--spacing-6);
  overflow: scroll;
} */
</style>
