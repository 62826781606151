<template>
  <div class="portal-card">
    <div :class="cssClasses">
      <h1 class="title">
        <slot name="title"> </slot>
      </h1>
      <h3 v-if="hasSubtitle" class="subtitle">
        <slot name="subtitle"> </slot>
      </h3>
      <button v-if="hasButton" class="button" @click="$emit('buttonClick')">
        <ion-icon v-if="hasButtonIcon" :name="buttonIconName"></ion-icon>
        {{ this.buttonTitle }}
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["buttonClick"],
  props: {
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonTitle: {
      type: Text,
      required: false,
      default: "",
    },
    buttonIconName: {
      type: Text,
      required: false,
      default: "",
    },
  },
  // created() {
  //   window.alert(this.buttonDisabled);
  // },
  computed: {
    hasSubtitle() {
      return !!this.$slots.subtitle;
    },
    hasButton() {
      return this.buttonTitle !== "";
    },
    hasButtonIcon() {
      return this.buttonIconName !== "";
    },
    cssClasses() {
      return { header: true, border: this.border };
    },
  },
};
</script>

<style scoped>
.portal-card {
  height: 100%;
  background-color: white;
  border: 0.5px solid #ddd;
  border-radius: var(--border-radius-3);
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.header {
  display: grid;
  padding: var(--spacing-6);
  row-gap: var(--spacing-3);
  grid-template-columns: auto min-content;
  /* justify-items: center; */
  align-items: center;
}

.border {
  border-bottom: 0.5px solid #ddd;
}

.title {
  font-weight: 500;
  color: #0a0b0d;
}

.subtitle {
  font-weight: 400;
  color: #5b616e;
  font-size: var(--font-size-4);
  grid-row: 2/3;
}

/* .main {
  border-top: var(--full-border-ultra-thin);
} */

.button {
  white-space: nowrap;
  grid-column: 2 /3;
  grid-row: 1/ 3;
  /* background-color: red; */
  border-radius: 11px;
  font-size: 1.8rem;
  background-color: #f3f8f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--spacing-3);
  padding: var(--spacing-4) var(--spacing-5);
}

.button:hover {
  background-color: #eef7f4;
}
</style>
