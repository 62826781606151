import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      isAuthenticated: false,
      email: null,
      emailVerified: false,
      firstName: "",
      lastName: "",
      tier: "",
      notifications: [],
    };
  },
  mutations,
  actions,
  getters,
};
