<template>
  <portal-view>
    <portal-header> Account Settings</portal-header>
    <main class="portal-main">
      <account-information></account-information>
      <subscription-information></subscription-information>
    </main>
  </portal-view>
</template>

<script>
import AccountInformation from "./users/AccountInformation.vue";
import SubscriptionInformation from "./users/SubscriptionInformation.vue";
export default {
  components: { AccountInformation, SubscriptionInformation },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.portal-main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  gap: var(--spacing-6);
  background-color: var(--primary-light-6);
  padding: var(--spacing-6);
  overflow: scroll;
}
</style>
