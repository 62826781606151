<template>
  <div
    v-for="notification in notifications"
    :key="notification.id"
    :id="notification.id"
    class="list"
  >
    <base-notification :notification="notification"></base-notification>
  </div>
</template>

<script>
import BaseNotification from "./BaseNotification.vue";
export default {
  components: { BaseNotification },
  data() {
    return {};
  },
  computed: {
    notifications() {
      return this.$store.getters["users/notifications"];
    },
  },
};
</script>

<style scoped>
/* .list {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 100%;
  transform: translate(-103%, 1rem);
  z-index: 100;
} */
</style>
