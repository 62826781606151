<template>
  <landing-outer colored>
    <landing-inner>
      <span class="subheading">Why Kaisdaq?</span>
      <h2 class="heading-secondary">
        A technology-first approach <br />
        to trading infrastructure
      </h2>
      <div class="why-grid">
        <div>
          <ion-icon class="why-icon" name="grid-outline"></ion-icon>
          <h3 class="why-step-title">Easy</h3>
          <p class="why-step-text">
            We provide up-to-date documentation that makes integrating your
            trading applications much easier
          </p>
        </div>

        <div>
          <ion-icon class="why-icon" name="timer-outline"></ion-icon>
          <h3 class="why-step-title">Fast</h3>
          <p class="why-step-text">
            We use state-of-the-art technologies to ensure your requests take
            only few milliseconds
          </p>
        </div>

        <div>
          <ion-icon class="why-icon" name="shield-checkmark-outline"></ion-icon>
          <h3 class="why-step-title">Secure</h3>
          <p class="why-step-text">
            We provide encrypted API Keys with latest security standards ro
            minimize your security threats
          </p>
        </div>

        <div>
          <ion-icon class="why-icon" name="radio-outline"></ion-icon>
          <h3 class="why-step-title">Reliable</h3>
          <p class="why-step-text">
            We have a robust error-handling protocol that makes troubleshooting
            your requests much simpler
          </p>
        </div>
      </div>
    </landing-inner>
  </landing-outer>
</template>

<script>
export default {};
</script>

<style scoped>
.why-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* row-gap: 4.8rem; */
  /* padding: 0 4rem; */
  /* padding-left: 4rem; */
  column-gap: 8rem;
  justify-items: center;
  /* align-items: center; */
  align-items: start;
}

.subheading {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: #08835e;
  /* text-transform: uppercase; */
  margin-bottom: 3.2rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
  font-weight: 500;
  color: rgb(10, 37, 64);
  /* color: #054934; */
  letter-spacing: -0.5px;
}

.why-step-title {
  /* color: rgb(10, 11, 13); */
  color: rgb(10, 37, 64);
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 1.2rem;
  margin-left: 0.4rem;
  font-weight: 500;
}

.why-step-text {
  color: rgb(66, 84, 102);
  font-size: 1.6rem;
  line-height: 1.5;
  margin-left: 0.4rem;
}

.why-icon {
  /* width: 20rem; */
  font-size: 4.4rem;
  color: #099268;
  margin-bottom: 1.2rem;
}
</style>
