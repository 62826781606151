export default {
  setEnvironments(state, payload) {
    state.environments = payload;
  },
  setEnvironmentType(state, payload) {
    state.environmentType = payload;
  },
  setSelectedEnvironment(state, payload) {
    state.selectedEnvironment = payload;
  },
};
