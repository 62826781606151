<template>
  <landing-outer colored>
    <landing-inner title="Unified platform">
      <!-- <span class="subheading">Unified platform</span> -->
      <!-- <h2 class="heading-secondary">
        Simplify your API trading <br />
        in 3 steps only
      </h2> -->
      <template v-slot:subtitle
        >Connect your trading application <br />
        to brokers in 3 steps only</template
      >

      <!-- <img class="session" src="@/assets/portal/session-1.png" /> -->
      <div class="how-grid">
        <div class="smartphone">
          <div class="content">
            <!-- <iframe src="/w3css/tryw3css_templates_band.htm" style="width:100%;border:none;height:100%" /> -->
            <img class="session" src="@/assets/portal/session-4.png" />
          </div>
        </div>
        <div></div>
        <div>
          <div class="how-step-header">
            <ion-icon class="how-step-icon" name="layers-outline"></ion-icon>
            <div class="how-step-number">1.</div>
            <h3 class="how-step-title">Activate your brokers</h3>
          </div>
          <p class="how-step-description">
            Your application should first activate your brokerages. Some brokers
            require additional verification before granting their unified
            access.
          </p>
        </div>
        <div>
          <div class="how-step-header">
            <ion-icon class="how-step-icon" name="link-outline"></ion-icon>
            <div class="how-step-number">2.</div>
            <h3 class="how-step-title">Connect broker accounts</h3>
          </div>
          <p class="how-step-description">
            Connections are only available for your active brokers. Your
            application only shares a permission with Kaisdaq to access your
            accounts, never your credentials!
          </p>
        </div>

        <div>
          <div class="how-step-header">
            <ion-icon
              class="how-step-icon"
              name="trending-up-outline"
            ></ion-icon>
            <div class="how-step-number">3.</div>
            <h3 class="how-step-title">Streamline your API requests</h3>
          </div>
          <p class="how-step-description">
            We provide a unified API to interact with any financial broker. You
            can place trades, list positions, scan assets using your secure API
            keys.
          </p>
        </div>
      </div>
    </landing-inner>
  </landing-outer>
</template>

<script>
export default {};
</script>

<style scoped>
.how-grid {
  /* margin-top: var(--spacing-5); */
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 0.5fr;
  /* row-gap: 4.8rem; */
  /* padding: 0 4rem; */
  /* padding-left: 4rem; */
  column-gap: 8rem;
  justify-items: start;
  /* align-items: center; */
  /* align-items: start; */
  align-items: center;
  /* align-content: center; */
}

/* The device with borders */
.smartphone {
  grid-row: 1 / -1;
  justify-self: center;
  position: relative;
  background: white;
  border: 9px #f8f9fa solid;
  border-radius: 36px;
  box-shadow: 0px 0px 150px 10px rgba(50, 50, 93, 0.25);
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 320px;
  height: auto;
  padding: var(--spacing-4) var(--spacing-4);
}
.session {
  width: 100%;
  height: 100%;
  border-radius: 36px;
}

.subheading {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: #08835e;
  /* text-transform: uppercase; */
  margin-bottom: 3.2rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
  font-weight: 500;
  color: rgb(10, 37, 64);
  /* color: #054934; */
  letter-spacing: -0.5px;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.how-step-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-6);
  margin-bottom: var(--spacing-4);
}

.how-step-title,
.how-step-number {
  /* color: rgb(10, 11, 13); */
  color: rgb(10, 37, 64);
  font-size: 1.8rem;
  /* line-height: 1.3; */
  /* margin-bottom: 1.2rem; */
  font-weight: 500;
}

.how-step-description {
  color: rgb(66, 84, 102);
  font-size: 1.6rem;
  line-height: 1.5;
}

.how-step-icon {
  /* width: 20rem; */
  font-size: 4.4rem;
  color: #099268;
  /* margin-bottom: 1.2rem; */
}
</style>
