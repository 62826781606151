<template>
  <portal-card
    v-if="!hasCurrentSubscription"
    buttonTitle="Upgrade"
    buttonIconName="arrow-up-circle-outline"
    buttonDisabled="true"
    @buttonClick="getCheckout"
  >
    <template v-slot:title> Subscription Information</template>
    <template v-slot:subtitle>
      Upgrade and get immediate access to the live environment
    </template>
    <div class="info-row">
      <div class="info-description">Tier</div>
      <div class="info-value">Free</div>
    </div>
    <div class="info-row">
      <div class="info-description">Status</div>
      <div class="info-value">Free</div>
    </div>
    <!-- <div class="info-row">
      <a class="info-description" @click="createPortal">Portal</a>
    </div> -->
  </portal-card>
  <portal-card
    v-else
    buttonTitle="Manage billing"
    buttonIconName="arrow-forward-circle-outline"
    @buttonClick="getPortal"
  >
    <template v-slot:title> Subscription Information</template>
    <template v-slot:subtitle>
      Easily manage your invoices and payment methods
    </template>
    <div class="info-row">
      <div class="info-description">Tier</div>
      <div class="info-value">Pay As You Go</div>
    </div>
    <div class="info-row">
      <div class="info-description">Status</div>
      <div class="info-value">{{ status }}</div>
    </div>
    <!-- <div class="info-row">
      <a class="info-description" @click="createPortal">Portal</a>
    </div> -->
  </portal-card>
</template>

<script>
export default {
  // data() {
  //   return {
  //     showForm: false,
  //   };
  // },
  computed: {
    hasCurrentSubscription() {
      // window.alert(this.$store.getters["payments/hasOpenSubscription"]);
      return this.$store.getters["payments/hasCurrentSubscription"];
    },
    status() {
      return this.$store.getters["payments/subscriptionStatus"];
    },
  },
  methods: {
    getCheckout() {
      // this.$store.dispatch("payments/stripeCheckout");
      // window.open("http://127.0.0.1:8000/payments/stripe/checkout");
      this.$store.commit("users/addNotification", {
        success: false,
        message:
          "Upcoming! To discuss your specific needs, please contact us at support@kaisdaq.com",
      });
    },
  },
};
</script>

<style scoped>
.info-row {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: var(--spacing-6);
  gap: var(--spacing-3);
  font-size: var(--font-size-4);
  /* color: var(--primary-black-1); */
  border-bottom: var(--full-border-ultra-thin);
}

.info-row:last-child {
  border-bottom: none;
}

.info-description {
  margin-right: auto;
  font-weight: 600;
}

.info-value {
  font-weight: 300;
}
</style>
