<template>
  <landing-outer>
    <landing-inner title="Billing">
      <template v-slot:subtitle
        >Pricing built for <br />
        developers of all sizes</template
      >
      <div class="grid">
        <div></div>
        <div class="table-item"><span class="subscription">Free</span></div>
        <div class="table-item">
          <!-- <span class="popular">Most popular</span> -->
          <div class="popular">
            <ion-icon name="ribbon-outline" class="popular-icon"></ion-icon>
            <span class="popular-text">Most popular</span>
          </div>
          <span class="subscription"
            >Pay as you go<sup class="sup">1</sup></span
          >
        </div>
        <div class="table-item">
          <span class="subscription">Customized<sup class="sup">2</sup></span>
        </div>
        <div class="table-feature">
          <div class="feature">No monthly minimum</div>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="yes-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="white-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="close-outline" class="no-icon"></ion-icon>
        </div>
        <div class="table-feature">
          <div class="feature">Sandbox environment</div>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="yes-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="white-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="yes-icon"></ion-icon>
        </div>
        <div class="table-feature">
          <div class="feature">Live environment</div>
        </div>
        <div class="table-item">
          <ion-icon name="close-outline" class="no-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="white-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="yes-icon"></ion-icon>
        </div>
        <div class="table-feature">
          <div class="feature">Volume discount</div>
        </div>
        <div class="table-item">
          <ion-icon name="close-outline" class="no-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="close-outline" class="white-icon"></ion-icon>
        </div>
        <div class="table-item">
          <ion-icon name="checkmark-outline" class="yes-icon"></ion-icon>
        </div>
        <div class="table-item"></div>
        <div class="table-item"></div>
        <div class="table-item"></div>
        <div class="table-item"></div>
      </div>
      <div class="footnotes">
        <div class="footnote">
          <sup>1</sup> A Pay as you go subscription charges a monthly fixed fee
          per brokerage connection in the Kaisdaq Live environment
        </div>
        <div class="footnote">
          <sup>2</sup> A customized subscription requires a minimum usage
          volume. To know more, please contact
          <a href="mailto:support@kaisdaq.com" class="sales">customer sales</a>
        </div>
      </div>
    </landing-inner>
  </landing-outer>
</template>

<script>
export default {};
</script>

<style scoped>
.grid {
  display: grid;
  column-gap: var(--spacing-7);
  grid-template-columns: 1fr 1fr 1.2fr 1fr;
  grid-template-rows: var(--spacing-12) repeat(4, var(--spacing-10)) var(
      --spacing-3
    );
  /* justify-content: center; */
  /* align-content: center; */
  /* justify-items: center; */
  /* align-items: center; */
  padding: 0 0 0 var(--spacing-7);
}

.grid > div:nth-child(3),
.grid > div:nth-child(7),
.grid > div:nth-child(11),
.grid > div:nth-child(15),
.grid > div:nth-child(19),
.grid > div:nth-child(23) {
  background-color: var(--primary-color);
  border: var(--font-size-5) solid var(--primary-color);
}

.grid > div:nth-child(3),
.grid > div:nth-child(7),
.grid > div:nth-child(11),
.grid > div:nth-child(15),
.grid > div:nth-child(19) {
  /* background-color: var(--primary-color);*/
  color: white;
}

.grid > div:nth-child(3) {
  border-radius: var(--border-radius-4) var(--border-radius-4) 0 0;
}

.grid > div:nth-child(23) {
  border-radius: 0 0 var(--border-radius-4) var(--border-radius-4);
}

.subscription {
  font-size: var(--font-size-6);
  /* margin-bottom: var(--spacing-4); */
  font-weight: 500;
  text-transform: uppercase;
}

.feature {
  font-size: var(--font-size-6);
  /* margin-bottom: var(--spacing-4); */
  font-weight: 300;
}

.yes-icon,
.no-icon,
.white-icon {
  font-size: var(--font-size-10);
}

.yes-icon {
  color: var(--primary-color);
}

.no-icon {
  color: var(--primary-red-color);
}

.white-icon {
  color: white;
}

.table-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.table-feature {
  display: flex;
  align-items: center;
}

.popular {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-1rem, -1rem);
  /* text-transform: uppercase; */
  /* color: white; */
  /* margin-right: var(--spacing-3); */
  /* font-size: var(--font-size-10); */
  display: flex;
  gap: var(--spacing-2);
  /* justify-content: center; */
  align-items: center;
}

.popular-icon {
  font-size: var(--font-size-7);
}

.popular-text {
  font-size: var(--font-size-4);
  font-weight: 300;
}

.sup {
  font-size: var(--font-size-3);
}

.footnotes {
  margin: var(--spacing-10) 0 0 var(--spacing-9);
}

.sales {
  color: var(--primary-color);
}

.sales:hover {
  color: var(--primary-dark-1);
}
</style>
