<template>
  <div :style="grid" class="form-grid"><slot></slot></div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    grid() {
      const repeat = "repeat(" + this.columns + ", minmax(0, 1fr))";
      return {
        "grid-template-columns": repeat,
      };
    },
  },
};
</script>

<style scoped>
.form-grid {
  /* position: relative; */
  /* max-width: 100%; */
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: var(--spacing-3);
  column-gap: var(--spacing-4);
  margin-bottom: var(--spacing-4);
  /* min-width: 0; */
}
</style>
