<template>
  <div class="content">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.content {
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: scroll;
}
</style>
