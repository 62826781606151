<template>
  <!-- <div class="landing-outer-container action-outer"> -->
  <landing-outer>
    <landing-inner>
      <!-- <h2 class="heading-secondary">Ready to get started?</h2> -->
      <template v-slot:subtitle>Ready to get started?</template>
      <div class="action-call">
        <p class="action-description">
          Create your account today and get a free access to your brokerage
          sandbox APIs
        </p>
        <router-link class="action-button" to="/signup">
          Start now!</router-link
        >
      </div>
    </landing-inner>
  </landing-outer>
</template>

<script>
export default {};
</script>

<style scoped>
.subheading {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: #08835e;
  /* text-transform: uppercase; */
  margin-bottom: 3.2rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  font-weight: 500;
  color: rgb(10, 37, 64);
  /* color: #054934; */
  letter-spacing: -0.5px;
}

.action-call {
  /* display: grid; */
  /* grid-template-columns: 4fr 1fr; */
  /* justify-items: center; */
  /* align-items: center; */
  /* column-gap: 2.4rem; */
  /* justify-content: center; */
  /* align-content: center; */
  margin-top: calc(-1 * var(--spacing-9));
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3.2rem;
}

.action-description {
  /* grid-column: 1 / 3; */
  color: rgb(66, 84, 102);
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 300;
  /* margin-bottom: 1.6rem; */
}

.action-button {
  /* grid-row: 2 / 3; */
  /* display: inline; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 2rem;
  background-color: #099268;
  padding: 1.2rem 2.4rem;
  border-radius: 50px;
  color: white;
}

.action-button:hover {
  background-color: #08835e;
}
</style>
