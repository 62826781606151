<template>
  <a v-if="link" class="nav-button" :href="to">
    <ion-icon :name="ionName" class="nav-icon" size="large"></ion-icon>
    <span class="nav-text">{{ this.title }}</span>
  </a>
  <router-link v-else class="nav-button" :to="{ name: this.to }">
    <ion-icon :name="ionName" class="nav-icon" size="large"></ion-icon>
    <span class="nav-text">{{ this.title }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      required: false,
    },
    title: {
      required: true,
    },
    ionName: {
      required: false,
    },
    action: {
      required: false,
      default: false,
    },
    link: {
      required: false,
      default: false,
    },
  },
  // created() {
  //   window.alert(this.link);
  // },
};
</script>

<style scoped>
.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-6);
  /* background-color: red; */
  border-radius: 50px;
  padding: var(--spacing-4) var(--spacing-6);
  height: 4rem;
}

.nav-text {
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
}

a:hover {
  background-color: var(--primary-grey-4);
}

.router-link-active {
  background-color: var(--primary-grey-3);
}
</style>
