<template>
  <div class="container">
    <landing-inner>
      <div class="hero-grid">
        <div class="hero-text-box">
          <h1 class="hero-text-title">Trading infrastructure for developers</h1>
          <h3 class="hero-text-subtitle">
            Focus on developing your application on the programming language and
            financial broker of your choice, we handle the rest!
          </h3>
        </div>
        <div class="hero-visual-box">
          <img
            class="hero-visual-brokerage-accounts"
            src="@/assets/portal/brokers-0.png"
          />
          <img
            class="hero-visual-api-keys"
            src="@/assets/portal/connections-0.png"
          />
        </div>
      </div>
    </landing-inner>
  </div>
</template>

<style scoped>
.container {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  padding: var(--spacing-8) 0 var(--spacing-12) 0;
  background-color: white;
}

.hero-grid {
  /* background-color: aqua; */
  /* width: 1140px; */
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: 1fr 1.2fr;
  /* align-items: center; */
  /* align-content: center; */
  /* padding: var(--spacing-8) 0rem; */
  column-gap: 12.8rem;
}

.hero-text-box {
  display: flex;
  flex-direction: column;
  /* align-items: space-between; */
  /* justify-content: space-evenly; */
  justify-content: center;
  gap: 2.4rem;
}

.hero-text-title {
  /* margin-top: 8rem; */
  font-size: 7.4rem;
  font-weight: 700;
  /* color: #0a0b0d; */
  color: rgb(58, 58, 58);
  line-height: 1.05;
}

.hero-text-subtitle {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.4;
  /* color: #0a0b0d; */
  color: rgb(66, 84, 102);
}

.hero-visual-box {
  /* background-color: wheat; */
  /* display: flex; */
  /* flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 3fr 1fr 3fr;
  /* overflow: hidden; */
}

.hero-visual-brokerage-accounts,
.hero-visual-api-keys {
  /* object-fit: cover; */
  width: 140%;
  /* height: 100%; */
  border-radius: 11px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* transform: scale(1.5); */
}

.hero-visual-brokerage-accounts {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.hero-visual-api-keys {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
}
</style>
