<template>
  <landing-outer>
    <landing-inner>
      <div v-html="markdownToHtml" class="content"></div> </landing-inner
  ></landing-outer>
</template>

<script>
// import TermsFile from "@/components/legal/TermsAndConditions.md";
import { marked } from "marked";

export default {
  props: {
    content: {
      type: Text,
      required: true,
    },
  },
  // mounted() {
  //   this.markdown = TermsFile;
  // },
  computed: {
    markdownToHtml() {
      return marked(this.content);
    },
  },
};
</script>

<style scoped>
.content >>> h1 {
  /* color: red; */
  /* font-weight: 100px; */
  font-size: var(--font-size-8);
  margin-top: calc(-1 * var(--spacing-9));
  margin-bottom: var(--spacing-7);
  color: var(--primary-black-1);
  /* margin-bottom: var(--spacing-7); */
}

.content >>> h2 {
  /* color: red; */
  /* font-weight: 100px; */
  font-size: var(--font-size-6);
  color: var(--primary-black-1);
  margin-bottom: var(--spacing-7);
  margin-top: var(--spacing-7);
}

.content >>> p {
  font-size: var(--font-size-4);
  margin-bottom: var(--spacing-4);
}

.content >>> ul {
  padding-left: var(--spacing-5);
  font-size: var(--font-size-4);
  list-style-type: circle;
  list-style-position: inside;
  margin-bottom: var(--spacing-4);
}

.content >>> ul ul {
  padding-left: var(--spacing-7);
  list-style-type: square;
}
</style>
