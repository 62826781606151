<template>
  <portal-view>
    <portal-header>Connections</portal-header>
    <portal-main>
      <linked-connections></linked-connections>
    </portal-main>
  </portal-view>
</template>

<script>
import LinkedConnections from "./LinkedConnections.vue";
export default {
  components: { LinkedConnections },
  data() {
    return {};
  },
};
</script>

<style scoped>
.connections {
  border-top: var(--full-border-ultra-thin);
}
</style>
