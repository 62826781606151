export default {
  async environmentList(context) {
    const environmentsUrl = process.env.VUE_APP_BACKEND_URL + "/environments";
    const response = await fetch(environmentsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    context.commit("setEnvironments", responseData);
  },
  async environmentCreate(context, payload) {
    const environmentsUrl = process.env.VUE_APP_BACKEND_URL + "/environments";
    const response = await fetch(environmentsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    context.commit("setEnvironments", responseData);
  },
};
