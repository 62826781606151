<template>
  <div class="form-error">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.form-error {
  color: var(--primary-red-color);
  font-size: 1.4rem;
  min-height: 2rem;
  overflow: hidden;
  font-weight: 400;
}
</style>
