<template>
  <router-link
    v-if="link"
    :to="to"
    class="button"
    :class="mode"
    :disabled="disabled"
    ><slot></slot
  ></router-link>
  <button v-else class="button" :class="mode" :disabled="disabled">
    <!-- <base-loader v-if="isLoading"></base-loader> -->
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "full",
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>
.button {
  box-sizing: border-box;
  width: 100%;
  padding: 2rem;
  border-radius: 50px;
  font-size: var(--font-size-6);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.full {
  background-color: var(--primary-color);
  color: #fff;
}

.outline {
  background-color: #eee;
  color: #111;
}

button:disabled,
a:disabled {
  cursor: not-allowed;
}
</style>
