export default {
  toggledConnections(state, getters, rootState) {
    // if (state.connections !== null) {
    //   return state.connections.filter(
    //     (item) => item.is_sandbox == rootGetters["brokers/isSandbox"]
    //   );
    // } else {
    //   return [];
    // }
    if (Array.isArray(state.connections) && state.connections.length > 0) {
      // window.alert("HEy");
      // console.log("Connections", state.connections);
      // console.log(
      //   "Connections filtered",
      //   state.connections.filter(
      //     (item) => item.activation.name == rootState.brokers.activationName
      //   )
      // );

      return state.connections.filter(
        (item) =>
          item.activation.environment.type ==
          rootState.environments.environmentType
      );
    } else {
      return [];
    }
  },
};
