export default {
  email(state) {
    return state.email;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  fullName(state) {
    return state.firstName + " " + state.lastName;
  },
  tier(state) {
    if (state.tier == "free") {
      return "Free";
    } else if (state.tier == "pay-as-you-go") {
      return "Pay As You Go";
    } else if (state.tier == "custom") {
      return "Custom";
    } else {
      return state.tier;
    }
  },
  emailVerified(state) {
    return state.emailVerified;
  },
  notifications(state) {
    state.notifications.forEach((item, i) => {
      item.id = i + 1;
    });
    return state.notifications;
  },
};
