// import { defineAsyncComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import SignInPage from "@/pages/SignInPage.vue";
import AppPage from "@/pages/AppPage.vue";
import Brokers from "@/components/portal/brokers/ZBrokers.vue";
import Connections from "@/components/portal/connections/ZConnections.vue";
import ApiKeys from "@/components/portal/keys/ZKeys.vue";
import AccountSettings from "@/components/portal/AccountSettings.vue";
import SignUpPage from "@/pages/SignUpPage.vue";
import LandingPage from "@/components/landing/ZLanding.vue";
import TermsPage from "@/pages/legal/TermsPage.vue";
import PrivacyPage from "@/pages/legal/PrivacyPage.vue";
import DonotsellPage from "@/pages/legal/DonotsellPage.vue";
import CaliforniaPage from "@/pages/legal/CaliforniaPage.vue";
// const SignUpPage = defineAsyncComponent(() => import("@/pages/SignUpPage.vue"));

const routes = [
  {
    path: "/signin",
    name: "signin",
    component: SignInPage,
    meta: {
      title: "Kaisdaq - Sign in",
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpPage,
    meta: {
      title: "Kaisdaq - Sign up",
      requiresAuth: false,
    },
  },
  {
    path: "/app",
    name: "app",
    component: AppPage,
    redirect: (to) => ({
      path: "/app/brokers",
      query: {
        message: to.params.message,
        success: to.params.success,
      },
    }),
    children: [
      {
        name: "brokers",
        path: "brokers",
        component: Brokers,
        meta: { title: "Kaisdaq - Your active brokerages" },
      },
      {
        name: "connections",
        path: "connections",
        component: Connections,
        meta: { title: "Kaisdaq - Your connected brokerages" },
      },

      {
        name: "keys",
        path: "keys",
        component: ApiKeys,
        meta: { title: "Kaisdaq - Your API Keys" },
      },
      {
        name: "account-settings",
        path: "account-settings",
        component: AccountSettings,
        meta: { title: "Kaisdaq - Account Settings" },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/legal/terms",
    // "name": "legal/term",
    meta: { title: "Kaisdaq - Terms and Conditions" },
    component: TermsPage,
  },
  {
    path: "/legal/privacy",
    // "name": "legal/term",
    meta: { title: "Kaisdaq - Privacy Notice" },
    component: PrivacyPage,
  },
  {
    path: "/legal/donotsell",
    // "name": "legal/term",
    meta: { title: "Kaisdaq - Do Not Sell My Information" },
    component: DonotsellPage,
  },
  {
    path: "/legal/california",
    // "name": "legal/term",
    meta: { title: "Kaisdaq - California Privacy Notice" },
    component: CaliforniaPage,
  },
  {
    path: "/",
    name: "landing-page",
    component: LandingPage,
    meta: {
      title: "Kaisdaq - Trading infrastructure for developers",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  // mode: history,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if (to.meta.requiresAuth && !store.getters["users/isAuthenticated"]) {
    return next({
      path: "/signin",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

// router.afterEach((to, from) => {
//   // window.alert(to.params.success);
//   console.log("FROM PARAMS", from.params);
// });

// router.beforeEach((to, from) => {
//   // instead of having to check every route record with
//   // to.matched.some(record => record.meta.requiresAuth)
//   if (to.meta.requiresAuth && !auth.isLoggedIn()) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     return {
//       path: "/login",
//       // save the location we were at to come back later
//       query: { redirect: to.fullPath },
//     };
//   }
// });

export default router;
