import { createApp } from "vue";
// import { defineAsyncComponent } from "vue";

//  Importing core parts
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Importing components
// import BaseNotification from "./components/interface/BaseNotification.vue";
import LogoWritten from "./components/interface/LogoWritten.vue";
import LogoVisual from "./components/interface/LogoVisual.vue";
import LogoCombined from "./components/interface/LogoCombined.vue";
import BaseLoader from "./components/interface/BaseLoader.vue";
import FormCell from "./components/forms/FormCell.vue";
import FormButton from "./components/forms/FormButton.vue";
import FormError from "./components/forms/FormButton.vue";
import FormHeader from "./components/forms/FormHeader.vue";
import FormContainer from "./components/forms/FormContainer.vue";
import FormGrid from "./components/forms/FormGrid.vue";
import PortalHeader from "@/components/portal/common/PortalHeader.vue";
import PortalMain from "@/components/portal/common/PortalMain.vue";
import PortalCard from "@/components/portal/common/PortalCard.vue";
import PortalView from "@/components/portal/common/PortalView.vue";
import PortalButton from "@/components/portal/common/PortalButton.vue";
import LandingInner from "@/components/landing/LandingInner.vue";
import LandingOuter from "@/components/landing/LandingOuter.vue";

import globalMixin from "./mixins.js";

// const MyTest = defineAsyncComponent(() => import("./components/MyTest.vue"));

const app = createApp(App);

app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith("ion-icon");
};

app.use(store);
app.use(router);

// app.component("base-notification", BaseNotification);
app.component("base-loader", BaseLoader);
app.component("logo-written", LogoWritten);
app.component("logo-visual", LogoVisual);
app.component("logo-combined", LogoCombined);
app.component("form-cell", FormCell);
app.component("form-button", FormButton);
app.component("form-error", FormError);
app.component("form-header", FormHeader);
app.component("form-container", FormContainer);
app.component("form-grid", FormGrid);
app.component("portal-header", PortalHeader);
app.component("portal-main", PortalMain);
app.component("portal-card", PortalCard);
app.component("portal-view", PortalView);
app.component("portal-button", PortalButton);
app.component("landing-inner", LandingInner);
app.component("landing-outer", LandingOuter);

app.mixin(globalMixin);

app.mount("#app");
