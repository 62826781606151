export default {
  async verifyEmail() {
    const payload = {
      title: "Please Verify Your Email Address",
      message:
        "In order to start using your Kaisdaq account, you need to confirm your email address.",
      has_button: true,
      button_reverse_url: "user:verify",
      button_description: "Verify your email address",
    };

    let url = process.env.VUE_APP_BACKEND_URL + "/emails";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      credentials: "include",
    });

    // this.$store.commit("users/addNotification", {
    //   success: true,
    //   message:
    //     "A new API key was created. Make sure to copy this key-associated secret as you won't see that again!",
    //   value: data.secret,
    // });

    // context.commit("users/addNotification");

    if (response.ok) {
      this.commit("users/addNotification", {
        success: true,
        message: "An email verification link has been sent to you.",
      });
    } else {
      this.commit("users/addNotification", {
        success: false,
        message: "There was an error sending the email verification link",
      });
    }
  },
};
