<template>
  <button class="button" @click="$emit('click')">
    <ion-icon v-if="hasButtonIcon" :name="buttonIconName"></ion-icon>
    {{ this.buttonTitle }}
  </button>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    buttonTitle: {
      type: Text,
      required: false,
      default: "",
    },
    buttonIconName: {
      type: Text,
      required: false,
      default: "",
    },
  },
  computed: {
    hasSubtitle() {
      return !!this.$slots.subtitle;
    },
    hasButton() {
      return this.buttonTitle !== "";
    },
    hasButtonIcon() {
      return this.buttonIconName !== "";
    },
  },
};
</script>

<style scoped>
.button {
  white-space: nowrap;
  grid-column: 2 /3;
  grid-row: 1/ 3;
  border-radius: 11px;
  font-size: 1.8rem;
  background-color: #f3f8f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--spacing-3);
  padding: var(--spacing-4) var(--spacing-5);
}

.button:hover {
  background-color: #eef7f4;
}
</style>
