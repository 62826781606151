<template>
  <main class="portal-main"><slot></slot></main>
</template>

<script>
export default {};
</script>

<style scoped>
.portal-main {
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%; */
  /* display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px; */
  /* gap: var(--spacing-6); */
  background-color: var(--primary-light-6);
  padding: var(--spacing-6);
  overflow: scroll;
}
</style>
