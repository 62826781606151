<template>
  <form-container width="75" @customSubmit="submitRegister">
    <form-header>Create an account</form-header>
    <form-grid columns="2">
      <form-cell
        type="text"
        placeholder="Your first name"
        label="First Name"
        v-model.trim="firstName"
        :error="this.firstNameError"
        @focus="resetError('first_name')"
      ></form-cell>
      <form-cell
        type="text"
        placeholder="Your last name"
        label="Last Name"
        v-model.trim="lastName"
        :error="this.lastNameError"
        @focus="resetError('last_name')"
      ></form-cell>
      <form-cell
        class="email"
        type="email"
        placeholder="Your email address"
        label="Email"
        v-model.trim="email"
        :error="this.emailError"
        @focus="resetError('email')"
      ></form-cell>
      <form-cell
        class="password"
        type="password"
        placeholder="Your password"
        label="Password"
        v-model.trim="password"
        :error="this.lastError"
        @focus="resetLastError()"
      ></form-cell>
    </form-grid>
    <div class="policy">
      <input type="checkbox" class="policy-checkbox" v-model="policyChecked" />
      <p class="policy-text">
        I certify that I am 18 years of age or older, agree to the
        <router-link class="plain-link" to="/legal/terms"
          >Terms and Conditions</router-link
        >, and acknowledge the
        <router-link class="plain-link" to="/legal/privacy"
          >Privacy Policy</router-link
        >
      </p>
    </div>
    <form-button :disabled="policyChecked === false"
      >Create free account</form-button
    >
  </form-container>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      errors: "",
      lastError: "",
      policyChecked: false,
    };
  },
  methods: {
    async submitRegister() {
      const actionPayload = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
      };
      let success = false;
      try {
        await this.$store.dispatch("users/register", actionPayload);
        await this.$store.dispatch("users/login", actionPayload);
        success = true;
      } catch (err) {
        if ("response" in err) {
          this.errors = err.response;
        } else {
          this.lastError = "Failed to register, please try again later.";
        }
      }
      if (success) {
        this.$router.push({ name: "brokers" });
        this.$store.dispatch("emails/verifyEmail");

        const environmentPayload = {
          description: "User sandbox environment",
          type: "sandbox",
          is_individual: false,
        };
        try {
          await this.$store.dispatch(
            "environments/environmentCreate",
            environmentPayload
          );
        } catch (err) {
          this.$store.commit("users/addNotification", {
            success: false,
            message:
              "There was an error creating your sandbox environment. Please reach out to support@kaisdaq.com",
          });
        }
      }
    },
    resetError(name) {
      delete this.errors[name];
    },
    resetLastError() {
      this.lastError = "";
    },
    getError(name) {
      const errors = this.errors[name];
      if (errors) {
        return errors[0];
      } else {
        return "";
      }
    },
  },
  watch: {
    errors() {
      this.lastError =
        this.getError("password") || this.getError("non_field_errors");
    },
  },
  computed: {
    emailError() {
      return this.getError("email");
    },
    firstNameError() {
      return this.getError("first_name");
    },
    lastNameError() {
      return this.getError("last_name");
    },
  },
};
</script>

<style scoped>
.email,
.password {
  grid-column: 1/3;
}

.policy {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-5);
  /* justify-content: center; */
  align-items: center;
  margin-bottom: var(--spacing-7);
}

.policy-text {
  font-size: var(--font-size-5);
}

a,
a:link {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-hover-color);
  font-weight: 600;
}
</style>
