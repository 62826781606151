<template>
  <router-link to="/">
    <svg
      :class="cssClasses"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 366.72 360"
    >
      <path
        class="b"
        d="M241.22,222.4l121.96,122.24c5.66,5.67,1.64,15.36-6.37,15.36h-108.59c-2.38,0-4.67-.94-6.35-2.63l-107.28-106.91c-9.46-9.42-25.59-2.73-25.59,10.62v13.91c0,8.28-6.72,15-15,15h-22.25c-1.66,0-3,1.34-3,3v64c0,1.66-1.34,3-3,3h-21.5c-1.66,0-3-1.34-3-3v-64c0-1.66-1.34-3-3-3H15c-8.28,0-15-6.72-15-15V85c0-8.28,6.72-15,15-15h22.5c1.66,0,3-1.34,3-3V3c0-1.66,1.34-3,3-3h21.5c1.66,0,3,1.34,3,3V67c0,1.66,1.34,3,3,3h23c8.28,0,15,6.72,15,15v13.29c0,13.36,16.16,20.06,25.61,10.61L240.86,2.64c1.69-1.69,3.98-2.64,6.36-2.64h110.47c8.03,0,12.04,9.71,6.35,15.38l-122.68,122.12c-23.5,23.4-23.57,61.42-.15,84.9Z"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  props: {
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "medium",
    },
  },
  computed: {
    cssClasses() {
      return {
        light: this.light,
        full: !this.light,
        medium: this.size == "medium",
        large: this.size == "large",
      };
    },
  },
};
</script>

<style scoped>
svg {
  width: 3rem;
}

.medium {
  width: 3rem;
}

.large {
  width: 3.9rem;
}

.full {
  fill: var(--primary-color);
}

.light {
  fill: var(--primary-light-color);
}

/* svg:hover {
  background-color: transparent;
  animation: spin 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
} */
</style>
