export default {
  async subscriptionInfo(context) {
    const subscriptionUrl =
      process.env.VUE_APP_BACKEND_URL + "/payments/subscription";
    const response = await fetch(subscriptionUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();

    if (!response.ok) {
      console.log("Problem", response);
    } else {
      console.log("Subscription Info", responseData);
      context.commit("setSubscriptionInfo", responseData);
    }
  },
  async stripeCheckout() {
    const checkoutUrl =
      process.env.VUE_APP_BACKEND_URL + "/payments/stripe/checkout";
    window.open(checkoutUrl);
  },
  async stripePortal() {
    const portalUrl =
      process.env.VUE_APP_BACKEND_URL + "/payments/stripe/portal";
    window.open(portalUrl);
  },
};
