import { createStore } from "vuex";
import usersModule from "./modules/users/index.js";
import environmentsModule from "./modules/environments/index.js";
import emailsModule from "./modules/emails/index.js";
import filesModule from "./modules/files/index.js";
// import accountsModule from "./modules/accounts/index.js";
import activationsModule from "./modules/activations/index.js";
import brokersModule from "./modules/brokers/index.js";
import connectionsModule from "./modules/connections/index.js";
import keysModule from "./modules/keys/index.js";
import paymentsModule from "./modules/payments/index.js";

export default createStore({
  modules: {
    users: usersModule,
    emails: emailsModule,
    activations: activationsModule,
    files: filesModule,
    payments: paymentsModule,
    environments: environmentsModule,
    connections: connectionsModule,
    keys: keysModule,
    brokers: brokersModule,
  },
});
