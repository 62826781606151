<template>
  <portal-card
    buttonTitle="Add new API key"
    buttonIconName="add-circle"
    @buttonClick="openForm"
  >
    <template v-slot:title>Your API keys</template>
    <template v-slot:subtitle
      >Access your trading account using secure keys</template
    >
    <create-form
      :show="showForm"
      :environmentType="environmentType"
      title="An error occurred"
      @closeForm="closeForm"
    >
    </create-form>
    <div v-if="noToggledKeys" class="no-data-to-show">
      You haven't created any API keys for this environment.
    </div>
    <div v-else class="api-keys-table">
      <div class="api-keys-row api-keys-row-header">
        <div>Key ID</div>
        <div>Nickname</div>
        <div></div>
      </div>
      <ul>
        <li v-for="key in toggledKeys" :key="key.id" :id="key.id">
          <key-representation :apiKey="key"></key-representation>
        </li>
      </ul>
    </div>
  </portal-card>
</template>

<script>
import PortalCard from "../common/PortalCard.vue";
import CreateForm from "./CreateForm.vue";
import KeyRepresentation from "./KeyRepresentation.vue";
export default {
  components: { CreateForm, KeyRepresentation, PortalCard },
  data() {
    return {
      showForm: false,
    };
  },
  computed: {
    environmentType() {
      return this.$store.getters["environments/environmentType"];
    },
    toggledKeys() {
      return this.$store.getters["keys/toggledKeys"];
    },
    noToggledKeys() {
      return this.$store.getters["keys/toggledKeys"].length == 0;
    },
  },
  methods: {
    openForm() {
      this.showForm = true;
      // this.loadKeys();
    },
    closeForm() {
      this.showForm = false;
      // this.loadKeys();
    },
  },
};
</script>

<style scoped>
.no-data-to-show {
  font-size: 2rem;
  font-weight: 300;
  background-color: #f9faf9;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
}

/* API KEYS TABLE*/

.api-keys-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.2fr;
  justify-items: center;
  align-items: center;
  border-bottom: 0.5px solid #ddd;
  padding: 2rem 0;
  color: #5b616e;
  transition: all 0.3s ease-in-out;
}

.api-keys-row-header {
  font-size: 1.6rem;
  font-weight: 300;
}

.api-keys-row-element {
  font-size: 1.6rem;
  font-weight: 400;
  /* background-color: red; */
}
</style>
