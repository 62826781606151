<template>
  <div v-if="popup" @click="$emit('closePopup')" class="backdrop"></div>
  <form
    :style="style"
    :class="cssClasses"
    @submit.prevent="$emit('customSubmit')"
  >
    <slot></slot>
  </form>
</template>

<script>
export default {
  emits: ["closePopup"],
  props: {
    width: {
      type: Number,
      required: false,
      default: 45,
    },
    popup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    // cssProps() {
    //   return {
    //     "--form-container-width": 45,
    //   };
    // },
    cssClasses() {
      return { popup: this.popup, full: !this.popup };
    },
    style() {
      return { width: this.width + "rem" };
    },
  },
};
</script>

<style scoped>
.full,
.popup {
  padding: 5rem;
  width: 75rem;
}

.full {
  margin-left: auto;
  margin-right: auto;
  margin-top: 9rem;
  margin-bottom: 9rem;
  background-color: #fff;
  border-radius: 1.5rem;
  border: 2px solid #eee;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 11px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: hidden;
  background-color: white;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 10;
}
</style>
