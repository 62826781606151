<template>
  <header class="landing-outer-container section-header">
    <nav class="landing-inner-container section-nav">
      <logo-combined class="vertical-centering"></logo-combined>
      <div class="nav-top-middle">
        <!-- <button class="nav-top-middle-button vertical-centering">Learn</button> -->
        <a
          class="nav-top-middle-button vertical-centering"
          href="https://kaisdaq.readme.io/reference"
        >
          API Documentation
        </a>
      </div>
      <div class="nav-top-sign vertical-centering">
        <router-link class="nav-top-sign-in-button" to="/signin"
          >Sign in</router-link
        >
        <router-link class="nav-top-sign-up-button" to="/signup"
          >Sign up</router-link
        >
      </div>
    </nav>
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
.section-header {
  /* height: 66px; */
  border-bottom: 0.5px solid #ebf0f1;
  /* flex-shrink: 0; */
}

.kaisdaq-logos {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.section-nav {
  /* padding: var(--spacing-4) 0; */
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  align-items: stretch;
  justify-content: space-between;

  /* padding: 2rem 0; */
  position: relative;

  /* max-width: 1180px; */
  /* margin-top: 0px; */
  /* margin-right: auto; */
  /* display: flex; */
  /* align-items: stretch; */
  /* padding-top: 0px; */
  /* padding-right: 20px; */
  /* box-sizing: content-box; */
  /* min-height: 66px;  */
}

.nav-top-middle {
  /* flex-grow: 6; */
  /* margin: auto; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2.4rem;
}

.nav-top-middle-button {
  /* display: block; */
  /* vertical-align: middle; */
  padding: 20px 0;
  display: flex; /*CSS3*/
  align-items: center; /*Vertical align*/
  font-size: 1.6rem;
  color: #050f19;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.nav-top-middle-button:hover {
  /* background-color: red; */
  border-bottom: 5px solid #099268;
  /* padding-bottom: -5px; */
}

.nav-top-sign {
  display: flex;
  flex-direction: row;
  /* align-items: stretch; */
  gap: 1rem;
}

.nav-top-sign-in-button,
.nav-top-sign-up-button {
  font-size: 1.6rem;
  padding: 1.2rem 1.2rem;
  border-radius: 7px;
}

.nav-top-sign-in-button {
  background-color: none;
}

.nav-top-sign-up-button {
  background-color: #099268;
  color: #fff;
}

.nav-top-sign-up-button:hover {
  background-color: #08835e;
}

.nav-top-sign-in-button:hover {
  color: #099268;
}
</style>
