<template>
  <div class="header">
    <!-- <logo-visual></logo-visual> -->
    <logo-written></logo-written>
    <div class="title"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Text,
      required: false,
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  margin-bottom: var(--spacing-8);
}

.title {
  font-weight: 900;
  font-size: 3.6rem;
}

/* .title.small {
  font-size: var(--font-size-7);
} */
</style>
