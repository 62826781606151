<template>
  <router-link to="/">
    <div class="written-logo">kaisdaq</div>
  </router-link>
</template>

<script>
export default {};
</script>

<style scope>
.written-logo {
  color: var(--primary-color);
  font-size: 2.8rem;
  font-weight: 600;
  /* font-family: "Rubik"; */
  text-transform: capitalize;
  letter-spacing: 2px;
  text-align: left;
}
</style>
