export default {
  subscriptionTier(state) {
    if (state.subscription_id !== "") {
      return "Free";
    } else {
      return "Pay As You Go";
    }
  },
  hasCurrentSubscription(state) {
    if (state.subscription_id !== "") {
      return true;
    } else {
      return false;
    }
  },
  subscriptionStatus(state) {
    if (state.subscription_status == "past_due") {
      return "Past Due";
    } else {
      return (
        state.subscription_status[0].toUpperCase() +
        state.subscription_status.substring(1)
      );
    }
  },
  subscriptionPlannedCancellation(state) {
    if (state.subscription_cancel_at !== null) {
      return true;
    }
    return false;
  },
  subscriptionCancellationTime(state) {
    return state.subscription_cancel_at;
  },
};
