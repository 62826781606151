<template>
  <div class="inner">
    <span v-if="hasTitle" class="title">{{ this.title }}</span>
    <h2 v-if="hasSubtitle" class="subtitle">
      <!-- {{ this.subtitle }} -->
      <slot name="subtitle"></slot>
    </h2>
    <!-- <slot name="subtitle"></slot> -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Text,
      required: false,
      default: null,
    },
    subtitle: {
      type: Text,
      required: false,
      default: null,
    },
  },
  computed: {
    hasTitle() {
      return this.title !== "";
    },
    hasSubtitle() {
      // !!this.$slots.subtitle
      return !!this.$slots.subtitle;
    },
  },
};
</script>

<style scoped>
.inner {
  display: flex;
  flex-direction: column;
  /* max-width: 1140px;
  flex-grow: 1; */
  width: 1140px;
  margin: auto;
}

.title {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: #08835e;
  /* text-transform: uppercase; */
  margin-bottom: 3.2rem;
  letter-spacing: 0.75px;
}

.subtitle {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: var(--spacing-11);
  font-weight: 500;
  color: rgb(10, 37, 64);
  /* color: #054934; */
  letter-spacing: -0.5px;
  /* max-width: 45%; */
}
</style>
