<template>
  <label class="switch">
    <input
      type="checkbox"
      v-model="isLive"
      :disabled="!hasCurrentSubscription"
    />
    <div class="slider round" :class="cssClasses">
      <!--ADDED HTML -->
      <span class="on">Live</span>
      <span class="off">Sandbox</span>
      <!--END-->
    </div>
    <div class="banner">
      Please upgrade your subscription to access live brokerages
    </div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      isLive: false,
    };
  },
  mounted() {
    const environmentType = this.$store.getters["environments/environmentType"];
    if (environmentType == "live") {
      this.isLive = true;
    }
  },
  computed: {
    hasCurrentSubscription() {
      return this.$store.getters["payments/hasCurrentSubscription"];
    },
    cssClasses() {
      return {
        slider: true,
        round: true,
        disabled: !this.hasCurrentSubscription,
      };
    },
  },
  watch: {
    isLive: {
      handler() {
        // window.alert("Hey");
        let environmentType;
        if (this.isLive == true) {
          environmentType = "live";
        } else {
          environmentType = "sandbox";
        }
        this.$store.commit("environments/setEnvironmentType", environmentType);
        const environment =
          this.$store.getters["environments/portalEnvironment"];
        this.$store.commit("environments/setSelectedEnvironment", environment);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  flex-basis: 120px;
  /* height: var(--spacing-7); */
  /* flex-grow: 1; */
  /* height: 3400px; */
  height: 34px;
  cursor: pointer;
}

.switch input {
  display: none;
}

/* .switch:hover {
  cursor: not-allowed;
} */

.slider {
  position: absolute;
  cursor: pointer;
  /* cursor: not-allowed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-light-3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.disabled {
  cursor: not-allowed;
  /* cursor: default; */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(86px);
  -ms-transform: translateX(86px);
  transform: translateX(86px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
  color: white;
  left: 35px;
  font-weight: 500;
  letter-spacing: 0.2rem;
}

.off {
  color: inherit;
  left: 38px;
  font-weight: 400;
}

.on,
.off {
  position: absolute;
  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */
  top: 50%;
  transform: translate(0%, -50%);
  font-size: 18px;
  /* font-family: Verdana, sans-serif; */
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.banner {
  /* position: absolute */
  font-size: var(--font-size-2);
  background-color: var(--primary-red-light);
  position: absolute;
  top: 100%;
  margin-top: var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-4);
  display: none;
  border-radius: var(--border-radius-2);
  /* color: white; */
  color: #333;
  z-index: 50;
  /* color: var(--primary-red-color); */
  /* transform: scale(1.5); */
  font-weight: 300;
}
.switch:hover .slider.disabled .banner {
  display: block;
}
</style>
