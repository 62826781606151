<template>
  <!-- <div class="app-header">Brokerage Accounts</div> -->
  <portal-view>
    <portal-header>Brokers</portal-header>
    <portal-main>
      <portal-card>
        <template v-slot:title>Your active brokers</template>
        <template v-slot:subtitle
          >A list of supported brokers for your application. To connect your
          account, first activate your broker and then visit
          <router-link to="connections" class="tab">Connections</router-link
          >.</template
        >
        <broker-cards></broker-cards>
      </portal-card>
    </portal-main>
  </portal-view>
</template>

<script>
import BrokerCards from "./BrokerCards.vue";
// import LinkedConnections from "./LinkedConnections.vue";
export default {
  components: { BrokerCards },
};
</script>

<style scoped>
.tab {
  /* font-weight: 300; */
  color: var(--primary-color);
  transition: all 0.1s ease-in-out;
}

.tab:hover {
  color: var(--primary-dark-1);
  font-weight: 400;
}
</style>
