<template>
  <teleport to="body">
    <form-container
      v-if="show"
      popup
      @closePopup="$emit('closeForm')"
      @customSubmit="submitCreate"
    >
      <form-header>Create new API Key</form-header>
      <form-grid>
        <form-cell
          type="text"
          placeholder="Your API Key nickname"
          label="Nickname"
          v-model.trim="nickname"
          :error="this.nicknameError"
          @focus="resetError('email')"
        ></form-cell>
        <form-cell
          type="text"
          placeholder="Your passphrase"
          label="Passphrase"
          v-model.trim="passphrase"
          value="passphrase"
          :error="this.lastError"
          @focus="resetLastError()"
        ></form-cell>
      </form-grid>
      <!-- <form-button link to="/signup">Submit</form-button> -->
      <form-button>Submit</form-button>
    </form-container>
  </teleport>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      passphrase: "",
      secret: "",
      errors: "",
      lastError: "",
      secretAvailable: false,
    };
  },
  emits: ["closeForm"],
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    environmentType: {
      required: true,
    },
  },
  methods: {
    // toggleForm() {
    //   this.show = !this.show;
    // },
    async submitCreate() {
      const actionPayload = {
        nickname: this.nickname,
        password: this.passphrase,
        environment_type: this.environmentType,
      };
      try {
        const data = await this.$store.dispatch(
          "keys/keyCreate",
          actionPayload
        );
        this.$store.commit("users/addNotification", {
          success: true,
          message:
            "A new API key was created. Make sure to copy this key-associated secret as you won't see that again!",
          value: data.secret,
        });
        this.$emit("closeForm");
        this.$store.dispatch("keys/keyList");
        this.nickname = "";
        this.passphrase = "";
      } catch (err) {
        if ("response" in err) {
          this.errors = err.response;
        } else {
          this.lastError =
            "Failed to create a new key, please try again later.";
        }
      }
    },
    resetError(name) {
      delete this.errors[name];
    },
    resetLastError() {
      this.lastError = "";
    },
    getError(name) {
      const errors = this.errors[name];
      if (errors) {
        return errors[0];
      } else {
        return "";
      }
    },
  },
  computed: {
    nicknameError() {
      return this.getError("nickname");
    },
  },
};
</script>

<style scoped></style>
