export default {
  async fileRetrieve(context, payload) {
    const url =
      process.env.VUE_APP_BACKEND_URL +
      "/files" +
      "?" +
      new URLSearchParams({
        name: payload.name,
      });
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      this.commit("files/setLegalContent", responseData);
    }
  },
};
