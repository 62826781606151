import router from "@/router/index.js";

export default {
  async createConnection(context, payload) {
    let oauthUrl =
      process.env.VUE_APP_BACKEND_URL +
      "/connections/" +
      payload.brokerageName +
      "/signin/";

    const response = await fetch(oauthUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const responseData = await response.json();

    console.log("Oauth connection response", responseData);
  },
  async connectionList(context) {
    const connectionsUrl = process.env.VUE_APP_BACKEND_URL + "/connections";
    const response = await fetch(connectionsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();
    console.log("RESPONSE", responseData);
    // if (!response.ok) {
    //   const error = new Error(
    //     responseData.message || "Failed to authenticate. Check your login data."
    //   );
    //   throw error;
    // }
    context.commit("setConnections", responseData);
  },
  async connectionSession(context, environmentType) {
    const url =
      process.env.VUE_APP_BACKEND_URL +
      "/connections/session" +
      "?" +
      new URLSearchParams({
        environment_type: environmentType,
        success_redirect_uri:
          process.env.VUE_APP_FRONTEND_URL + "/app/connections?success=true",
        failure_redirect_uri:
          process.env.VUE_APP_FRONTEND_URL + "/app/connections?success=false",
      });
    const isAuthenticated =
      localStorage.getItem("isAuthenticated") === "true" || false;
    if (!isAuthenticated) {
      router.push({
        path: "/signin",
        query: { redirect: url },
      });
    }
    window.open(url, "_blank");
  },
};
