<template>
  <landing-page>
    <landing-header></landing-header>
    <landing-hero></landing-hero>
    <landing-how></landing-how>
    <landing-pricing></landing-pricing>
    <landing-why></landing-why>
    <landing-action></landing-action>
    <landing-footer></landing-footer>
  </landing-page>
</template>

<script>
import LandingPage from "./LandingPage.vue";
import LandingHeader from "./LandingHeader.vue";
import LandingHero from "./LandingHero.vue";
import LandingHow from "./LandingHow.vue";
import LandingPricing from "./LandingPricing.vue";
import LandingWhy from "./LandingWhy.vue";
import LandingAction from "./LandingAction.vue";
import LandingFooter from "./LandingFooter.vue";

export default {
  components: {
    LandingPage,
    LandingHeader,
    LandingHero,
    LandingHow,
    LandingPricing,
    LandingWhy,
    LandingAction,
    LandingFooter,
  },
};
</script>

<style scoped></style>
