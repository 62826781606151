export default {
  async activationList(context) {
    const listUrl = process.env.VUE_APP_BACKEND_URL + "/activations";
    const response = await fetch(listUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();
    console.log("activation list response", responseData);
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to retrieve activations"
      );
      throw error;
    }
    context.commit("setActivations", responseData);
  },
  async activationCreate(context, payload) {
    console.log("activation create payload", payload);
    let createUrl = process.env.VUE_APP_BACKEND_URL + "/activations";

    const response = await fetch(createUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    console.log("activation create response", responseData);
    if (!response.ok) {
      throw {
        response: responseData,
        error: new Error(),
      };
    }

    // context.dispatch("getCredentials");
    // context.commit('users/addNotification')

    return responseData;
  },
};
