<template>
  <ul class="brokers-list">
    <div
      v-for="broker in toggledBrokers"
      class="broker-card"
      :key="broker.id"
      :aria-disabled="!broker.enabled_connections"
      :id="broker.id"
    >
      <img class="broker-logo" :src="broker.provider.logo" />
      <div class="broker-name">{{ broker.provider.name }}</div>
      <card-button :broker="broker" :activations="activations"></card-button>
    </div>
  </ul>
</template>

<script>
import CardButton from "./CardButton.vue";
export default {
  components: { CardButton },
  computed: {
    toggledBrokers() {
      return this.$store.getters["brokers/toggledBrokers"];
    },
    activations() {
      return this.$store.getters["activations/activations"];
    },
  },
};
</script>

<style scoped>
.brokers-list {
  padding: 2.4rem;
  display: grid;
  /* justify-content: center; */
  grid-template-columns: repeat(auto-fit, 17.8rem);
  grid-gap: 1rem;
}

.broker-card {
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: stretch; */
  justify-content: space-between;
  border: 0.5px solid #ddd;
  border-radius: 11px;
  gap: 2rem;
  /* width: 13rem; */
  /* position: relative; */
}

.broker-logo {
  height: 4.8rem;
  border-radius: 11px;
}

.broker-name {
  font-weight: 500;
  font-size: 1.8rem;
  text-align: left;
  line-height: 1.1;
}

.broker-available {
  font-weight: 300;
  font-size: var(--font-size-4);
  text-align: left;
}

/* a:hover {
  background-color: #f6f9f8;
} */

a:hover.disabled {
  cursor: not-allowed;
}

.broker-card:hover {
  background-color: var(--primary-grey-2);
}

.broker-card:hover card-button {
  background-color: red;
}
</style>
