<template>
  <div :class="cssProps"><slot></slot></div>
</template>

<script>
export default {
  props: {
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return {
        container: true,
        colored: this.colored,
      };
    },
  },
};
</script>

<style scoped>
.container {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* margin: auto; */
  /* display: block; */
  padding: 12.8rem 0;
  background-color: white;
  /* min-width: 1140px; */
  width: 100%;
}

.colored {
  background-color: var(--primary-light-6);
}
</style>
