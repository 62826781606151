<template>
  <portal-card
    buttonTitle="Add new connection"
    buttonIconName="add-circle"
    @buttonClick="connectionSession"
  >
    <template v-slot:title>Your connected accounts</template>
    <template v-slot:subtitle
      >Streamline your API requests by connecting new accounts on
      Kaisdaq</template
    >
    <div v-if="noToggledConnections" class="no-data-to-show">
      You haven't linked any brokerage accounts.
    </div>
    <div v-else>
      <div class="row row-header">
        <div>Connection ID</div>
        <div>Broker</div>
        <div>Broker-assigned User ID</div>
      </div>
      <ul>
        <li
          v-for="connection in toggledConnections"
          :key="connection.id"
          :id="connection.id"
        >
          <div class="row row-element">
            <div>{{ connection.id }}</div>
            <div class="brokerage-name-and-logo">
              <img
                class="brokerage-logo"
                :src="connection.activation.broker.provider.logo"
              />
              <p class="brokerage-name">
                {{ connection.activation.broker.provider.name }}
              </p>
            </div>

            <div>{{ connection.broker_user_id }}</div>
          </div>
        </li>
      </ul>
    </div>
  </portal-card>
</template>

<script>
import PortalCard from "../common/PortalCard.vue";
export default {
  components: { PortalCard },
  computed: {
    noToggledConnections() {
      return this.toggledConnections.length === 0;
    },
    toggledConnections() {
      return this.$store.getters["connections/toggledConnections"];
    },
  },
  methods: {
    connectionSession() {
      const environmentType =
        this.$store.getters["environments/environmentType"];
      this.$store.dispatch("connections/connectionSession", environmentType);
    },
  },
};
</script>

<style scoped>
.no-data-to-show {
  font-size: 2rem;
  font-weight: 300;
  background-color: #f9faf9;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  /* border-top: var(--full-border-ultra-thin); */
}

.row {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr;
  justify-items: center;
  align-items: center;
  border-bottom: 0.5px solid #ddd;
  padding: 2rem 0;
  color: #5b616e;
}

.row:hover {
  background-color: var(--primary-grey-2);
}

.row-header {
  font-size: 1.6rem;
  font-weight: 300;
}

.row-element {
  font-size: 1.6rem;
  font-weight: 400;
  /* background-color: red; */
}

.brokerage-name-and-logo {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 1.2rem;
}

.brokerage-logo {
  width: 3.6rem;
}

.brokerage-name {
  text-transform: capitalize;
}
</style>
