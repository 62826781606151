<template>
  <div class="form-cell">
    <label class="form-label" :for="this.label">{{ this.label }}</label>
    <input
      class="form-input"
      :type="this.type"
      :id="this.label"
      :placeholder="this.placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus')"
    />
    <form-error>{{ this.error }} </form-error>
  </div>
</template>

<script>
import FormError from "./FormError.vue";
export default {
  components: { FormError },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: false,
    },
  },
  emits: ["update:modelValue", "focus"],
};
</script>

<style scoped>
.form-cell {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.form-label {
  font-size: var(--font-size-4);
  font-weight: 500;
}

.form-input {
  padding: var(--spacing-5);
  font-size: var(--font-size-6);
  border-radius: var(--border-radius-3);
}
</style>
