<template>
  <!-- <base-notification v-show="showNotification" :success="success">{{
    this.message
  }}</base-notification> -->
  <loading-page v-if="isLoading"></loading-page>
  <div v-else class="app-layout">
    <!-- <div class="bar success">
      Upgrade your subscription to use <span>live</span> trading accounts
    </div> -->
    <!-- <portal-banners class="banner"></portal-banners> -->
    <!-- <div class="bar failure">Failure</div> -->
    <nav-bar class="nav"></nav-bar>
    <router-view class="view"></router-view>
  </div>
  <!-- <nav -->
</template>

<script>
import NavBar from "@/components/portal/nav/NavBar.vue";
// import PortalBanners from "@/components/portal/common/PortalBanners.vue";
import LoadingPage from "./LoadingPage.vue";
export default {
  components: { NavBar, LoadingPage },
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch("users/userInfo"),
      this.$store.dispatch("environments/environmentList"),
      this.$store.dispatch("brokers/brokerList"),
      this.$store.dispatch("activations/activationList"),
      this.$store.dispatch("connections/connectionList"),
      this.$store.dispatch("keys/keyList"),
      this.$store.dispatch("payments/subscriptionInfo"),
    ]);
    this.isLoading = false;
  },
  computed: {},
};
</script>

<style scoped>
.app-layout {
  height: 100vh;
  display: grid;
  grid-template-columns: min-content 1fr;
  /* grid-template-rows: min-content 1fr; */
  grid-template-rows: min-content 1fr;
}

.banner {
  grid-column: 1/3;
}

.nav {
  grid-row: 2/3;
}

.view {
  grid-row: 2/3;
  /* overflow: scroll; */
  /* overflow: hidden; */
}

/* router-view:first-child {
  grid-column: 2/3;
  grid-row: 2/3;
} */
</style>
