<template>
  <!-- <div class="kaisdaq-logos">
    <logo-visual class="vertical-centering"></logo-visual>
    <logo-written class="vertical-centering"></logo-written>
  </div> -->
  <router-link to="/" class="kaisdaq-logos">
    <logo-visual class="vertical-centering"></logo-visual>
    <logo-written class="vertical-centering"></logo-written>
  </router-link>
</template>

<script>
import LogoVisual from "./LogoVisual.vue";
import LogoWritten from "./LogoWritten.vue";

export default {
  components: {
    LogoVisual,
    LogoWritten,
  },
};
</script>

<style scoped>
.kaisdaq-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
}
</style>
