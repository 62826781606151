export default {
  toggledBrokers(state, getters, rootState) {
    if (Array.isArray(state.brokers) && state.brokers.length > 0) {
      return state.brokers.filter(
        (item) => item.type == rootState.environments.environmentType
      );
    } else {
      return [];
    }
  },
};
