export default {
  toggledKeys(state, getters, rootState, rootGetters) {
    if (Array.isArray(state.keys) && state.keys.length > 0) {
      return state.keys.filter(
        (item) =>
          item.environment.type == rootGetters["environments/environmentType"]
      );
    } else {
      return [];
    }
  },
};
