<template>
  <div class="api-keys-row api-keys-row-element">
    <div>{{ apiKey.id }}</div>
    <div>
      {{ apiKey.nickname }}
    </div>
    <ion-icon
      name="trash-outline"
      class="icon"
      @click="keyDestroy(apiKey.id)"
    ></ion-icon>
  </div>
</template>

<script>
export default {
  props: {
    apiKey: {
      required: true,
    },
  },
  methods: {
    async keyDestroy(id) {
      try {
        await this.$store.dispatch("keys/keyDestroy", id);
        await this.$store.dispatch("keys/keyList");
      } catch (error) {
        // console.log("Error", error);
      }
    },
  },
};
</script>

<style scoped>
/* .icon {
  transition: all 0.2s ease-in-out;
}  */

.icon,
.api-keys-row {
  transition: all 0.3 ease-in-out;
}

.icon {
  display: none;
}

.icon:hover {
  cursor: pointer;
}

/* .child{ display:none; } */
.api-keys-row:hover .icon {
  display: block;
  color: var(--primary-red-color);
}

.api-keys-row:hover {
  background-color: var(--primary-grey-2);
}
</style>
