var globalMixin = {
  methods: {
    getBrokerImage: function (type, brokerName) {
      const brokerId = brokerName.split("-")[0];
      return require("./assets/brokers/" + type + "/" + brokerId + ".png");
      // return require("./assets/brokers/logos/coinbase.png");
    },
  },
};

export default globalMixin;
