<template>
  <portal-card>
    <template v-slot:title> Account Information</template>
    <div class="info-row">
      <div class="info-description">Full Name</div>
      <div class="info-value">{{ this.fullName }}</div>
    </div>
    <div class="info-row">
      <div class="info-description">Email Address</div>
      <div class="info-value">
        <span v-if="emailVerified" class="verified">Verified</span>
        <span v-else class="unverified">
          <a @click="sendVerificationLink">Resend verification link</a>
          <span>Unverified</span>
        </span>
        {{ this.email }}
      </div>
    </div>
  </portal-card>
</template>

<script>
export default {
  computed: {
    fullName() {
      return this.$store.getters["users/fullName"];
    },
    email() {
      return this.$store.getters["users/email"];
    },
    emailVerified() {
      return this.$store.getters["users/emailVerified"];
    },
  },
  methods: {
    sendVerificationLink() {
      this.$store.dispatch("emails/verifyEmail");
      this.$store.dispatch("users/userInfo");
    },
  },
};
</script>

<style scoped>
.info-row {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: var(--spacing-6);
  gap: var(--spacing-3);
  font-size: var(--font-size-4);
  /* color: var(--primary-black-1); */
  border-bottom: var(--full-border-ultra-thin);
}

.info-row:last-child {
  border-bottom: none;
}

.info-description {
  margin-right: auto;
  font-weight: 600;
}

.info-value {
  font-weight: 300;
}

/* .verified,
.unverified {
  font-weight: 400;
} */

.verified {
  color: var(--primary-color);
}

.unverified {
  color: var(--primary-red-color);
}

/* .unverified:hover {
  display: none;
} */

.unverified a {
  display: none;
}

.unverified span {
  display: inline;
}

.unverified:hover span {
  display: none;
  /* transition: all 2s ease-in-out; */
}

.unverified:hover a {
  display: inline;
  /* transition: all 2s ease-in-out; */
}
</style>
