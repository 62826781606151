export default {
  terms(state) {
    return state.legalContent.terms;
  },
  privacy(state) {
    return state.legalContent.privacy;
  },
  donotsell(state) {
    return state.legalContent.donotsell;
  },
  california(state) {
    return state.legalContent.california;
  },
};
