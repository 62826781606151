<template>
  <div class="portal-header">
    <div><slot></slot></div>
    <portal-toggle></portal-toggle>
  </div>
</template>

<script>
import PortalToggle from "./PortalToggle.vue";

export default {
  components: { PortalToggle },
};
</script>

<style scoped>
.portal-header {
  padding: var(--spacing-6);
  border-bottom: 1px solid #ddd;
  font-size: 2.4rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
