<template>
  <div class="landing-page">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* .landing-page {
  overflow: scroll;
} */

.landing-page {
  /* overflow: hidden; */
  /* min-width: min-content; */
  overflow: scroll;
  margin: auto;
}
</style>
