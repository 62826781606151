import router from "@/router/index.js";

let slidingTimer;
let logoutTimer;
const slidingMargin = 3000;

export default {
  async register(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/users/register";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw {
        response: responseData,
        error: new Error(),
      };
    }
  },

  async login(context, payload) {
    let loginUrl = process.env.VUE_APP_BACKEND_URL + "/users/login";

    const response = await fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw {
        response: responseData,
        error: new Error(),
      };
    }

    localStorage.setItem("isAuthenticated", true);
    const expiresIn = responseData.expires_in * 1000;

    context.commit("setIsAuthenticated", true);
    slidingTimer = setTimeout(function () {
      context.dispatch("slide");
    }, expiresIn - slidingMargin);
  },

  async userInfo(context) {
    const infoUrl = process.env.VUE_APP_BACKEND_URL + "/users/info";

    const response = await fetch(infoUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      localStorage.setItem("isAuthenticated", false);
      context.commit("setIsAuthenticated", false);
      router.push("/signin");
    } else {
      const responseData = await response.json();
      context.commit("setInfo", responseData);
    }
  },

  async tryLogin(context) {
    const isAuthenticated =
      localStorage.getItem("isAuthenticated") === "true" || false;

    if (isAuthenticated) {
      await context.commit("setIsAuthenticated", true);
      let infoUrl = process.env.VUE_APP_BACKEND_URL + "/users/info";
      const response = await fetch(infoUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (!response.ok) {
        localStorage.setItem("isAuthenticated", false);
        context.commit("setIsAuthenticated", false);
        router.push("/signin");
      }
    }
  },

  timeout(context, ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  async slide(context) {
    clearTimeout(slidingTimer);
    let slideUrl = process.env.VUE_APP_BACKEND_URL + "/users/slide";
    const response = await fetch(slideUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      localStorage.setItem("isAuthenticated", false);
      context.commit("setIsAuthenticated", false);
      router.push("/signin");
    } else {
      const responseData = await response.json();
      const expiresIn = responseData.expires_in * 1000;
      logoutTimer = setTimeout(function () {
        context.dispatch("userLogout");
      }, expiresIn);
    }
  },

  async userLogout(context) {
    clearTimeout(logoutTimer);
    let logoutUrl = process.env.VUE_APP_BACKEND_URL + "/users/logout";
    await fetch(logoutUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    localStorage.setItem("isAuthenticated", false);
    context.commit("setIsAuthenticated", false);
    router.push("/signin");
  },
};
