export default {
  async keyList(context) {
    const keyUrl = process.env.VUE_APP_BACKEND_URL + "/keys";
    const response = await fetch(keyUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to retrieve keys"
      );
      throw error;
    }
    context.commit("setKeys", responseData);
  },
  async keyCreate(context, payload) {
    console.log("Create payload", payload);
    let createUrl = process.env.VUE_APP_BACKEND_URL + "/keys";

    const response = await fetch(createUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    console.log("REEEESP", responseData);
    if (!response.ok) {
      throw {
        response: responseData,
        error: new Error(),
      };
    }

    // context.dispatch("getCredentials");
    // context.commit('users/addNotification')

    return responseData;
  },
  async keyDestroy(context, id) {
    let destroyUrl = process.env.VUE_APP_BACKEND_URL + "/keys/" + id;
    const response = await fetch(destroyUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw {
        // response: responseData,
        error: new Error("Error deleting key"),
      };
    }
  },
};
