<template>
  <form-container @customSubmit="submitLogin">
    <form-header>Sign in to Kaisdaq</form-header>
    <form-grid>
      <form-cell
        type="email"
        placeholder="Your email address"
        label="Email"
        v-model.trim="email"
        :error="this.emailError"
        @focus="resetError('email')"
      ></form-cell>
      <form-cell
        type="password"
        placeholder="Your password"
        label="Password"
        v-model.trim="password"
        :error="this.lastError"
        @focus="resetLastError()"
      ></form-cell>
    </form-grid>
    <div class="form-buttons">
      <form-button mode="full">Login</form-button>
      <form-button mode="outline" link to="/signup">Create Account</form-button>
    </div>
  </form-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      errors: "",
      lastError: "",
    };
  },

  methods: {
    async submitLogin() {
      const actionPayload = {
        email: this.email,
        password: this.password,
      };
      try {
        await this.$store.dispatch("users/login", actionPayload);
        const redirectUrl = "/app";
        this.$router.replace(redirectUrl);
      } catch (err) {
        if ("response" in err) {
          this.errors = err.response;
        } else {
          this.lastError = "Failed to authenticate, please try again later.";
        }
      }
    },
    resetError(name) {
      delete this.errors[name];
    },
    resetLastError() {
      this.lastError = "";
    },
    getError(name) {
      const errors = this.errors[name];
      if (errors) {
        return errors[0];
      } else {
        return "";
      }
    },
  },
  watch: {
    errors() {
      this.lastError =
        this.getError("password") || this.getError("non_field_errors");
    },
  },
  computed: {
    emailError() {
      return this.getError("email");
    },
  },
};
</script>

<style scoped>
.form-buttons {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
