export default {
  async brokerList(context) {
    const brokerUrl = process.env.VUE_APP_BACKEND_URL + "/brokers";
    const response = await fetch(brokerUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to retrieve brokers"
      );
      throw error;
    }
    context.commit("setBrokers", responseData);
  },
};
