<template>
  <nav class="app-nav">
    <!-- <img
      class="kaisdaq-logo"
      alt="Kaisdaq logo"
      src="@/assets/logos/kaisdaq-logo-favico-app.svg"
    /> -->
    <logo-visual class="nav-logo" size="large"></logo-visual>
    <nav-button
      to="brokers"
      title="Brokers"
      ionName="layers-outline"
    ></nav-button>
    <nav-button
      to="connections"
      title="Connections"
      ionName="link"
    ></nav-button>
    <nav-button to="keys" title="Keys" ionName="key-outline"></nav-button>
    <nav-button
      link="true"
      :to="documentationUrl"
      title="API Documentation"
      ionName="document-text-outline"
    ></nav-button>
    <nav-button
      to="account-settings"
      title="Account Settings"
      ionName="person-circle-outline"
    ></nav-button>
    <nav-button
      link="true"
      title="Signout"
      ionName="log-out-outline"
      @click="userLogout"
    ></nav-button>
  </nav>
</template>

<script>
import NavButton from "./NavButton.vue";
export default {
  components: { NavButton },
  computed: {
    documentationUrl() {
      return process.env.VUE_APP_API_DOCUMENTATION_URL;
    },
  },
  methods: {
    userLogout() {
      this.$store.dispatch("users/userLogout");
    },
  },
};
</script>

<style scoped>
.app-nav {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  justify-content: center;
  position: relative;
  /* grid-column: 1/2; */
  /* grid-row: 1/3; */
  padding: var(--spacing-5);
  /* margin: 0 10rem; */
  border-right: var(--full-border-thin);
}

.nav-logo {
  /* height: 5.2rem; */
  margin-top: 1.9rem;
  margin-left: var(--spacing-7);
  position: absolute;
  top: 0%;
}
</style>
