<template>
  <base-notifications></base-notifications>
  <router-view></router-view>
</template>

<script>
// import { useRouter } from "vue-router";

import BaseNotifications from "@/components/interface/BaseNotifications.vue";
export default {
  setup() {
    // const router = useRouter();
    // const route = useRoute();
  },
  created() {
    this.$store.dispatch("users/tryLogin");
  },
  async mounted() {
    await this.$router.isReady();
    const message = this.$route.query.message || "";
    const success = this.$route.query.success === "true";

    if (message !== "") {
      this.$store.commit("users/addNotification", {
        success: success,
        message: message,
      });
    }
  },
  components: { BaseNotifications },
  // unmounted() {
  //   window.alert("HE");
  //   localStorage.setItem("sliding", false);
  // },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:root {
  --primary-color: #099268;
  --primary-hover-color: #08835e;
  --primary-light-color: #f3faf8;

  --primary-light-6: #f8fcfb;
  --primary-light-5: #f3faf8;
  --primary-light-4: #ebf6f3;
  --primary-light-3: #e6f4f0;
  --primary-light-2: #cee9e1;
  --primary-light-1: #b5ded2;

  --primary-grey-1: #f9faf9;
  --primary-grey-2: #f6f9f8;
  --primary-grey-3: #f3f8f7;
  --primary-grey-4: #eef7f4;

  --primary-dark-1: #08835e;
  --primary-dark-2: #077553;
  --primary-dark-3: #066649;
  --primary-dark-4: #05583e;
  --primary-dark-5: #054934;
  --primary-dark-6: #043a2a;

  --primary-red-color: #e03131;
  --primary-red-light: #fff5f5;
  --primary-red-dark: #c92a2a;
  --primary-black-1: #0a0b0d;

  --font-size-1: 1rem;
  --font-size-2: 1.2rem;
  --font-size-3: 1.4rem;
  --font-size-4: 1.6rem;
  --font-size-5: 1.8rem;
  --font-size-6: 2rem;
  --font-size-7: 2.4rem;
  --font-size-8: 3rem;
  --font-size-9: 3.6rem;
  --font-size-10: 4.4rem;
  --font-size-11: 5.2rem;
  --font-size-12: 6.2rem;
  --font-size-13: 7.4rem;
  --font-size-14: 8.6rem;
  --font-size-15: 9.8rem;
  --spacing-1: 0.2rem;
  --spacing-2: 0.4rem;
  --spacing-3: 0.8rem;
  --spacing-4: 1.2rem;
  --spacing-5: 1.6rem;
  --spacing-6: 2.4rem;
  --spacing-7: 3.2rem;
  --spacing-8: 4.8rem;
  --spacing-9: 6.4rem;
  --spacing-10: 8rem;
  --spacing-11: 9.6rem;
  --spacing-12: 12.8rem;
  --border-radius-1: 7px;
  --border-radius-2: 9px;
  --border-radius-3: 11px;
  --border-radius-4: 15px;
  --border-radius-5: 20px;
  --full-border-thin: 1px solid #ddd;
  --full-border-ultra-thin: 0.5px solid #ddd;
}

body {
  font-family: "Signika Negative", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
}

a,
a:link,
a:visited,
a:any-link,
button,
button:link,
button:visited {
  /* all: revert; */
  box-sizing: border-box;
  all: unset;
  display: inline-block;
  text-decoration: none;

  border: none;
  cursor: pointer;
  font-family: inherit;

  /* Put transition on original state */
  transition: all 0.1s;
  text-align: center;

  /* height: 200px; */
  /* writing-mode: horizontal-tb; */
}

input {
  text-decoration: none;
  border: 2px solid #ddd;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.vertical-centering {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontal-centering {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-outer-container {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* align-items: center; */
}

.landing-inner-container {
  display: flex;
  flex-direction: column;
  width: 1140px;
  /* flex-grow: 1; */
  margin: auto;
}

/* .portal-main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  gap: var(--spacing-6);
  background-color: var(--primary-light-6);
  padding: var(--spacing-6);
  overflow: scroll;
} */
</style>
