<template>
  <button :class="cssClasses" @click="activationCreate">
    <ion-icon :name="activationStatus.icon" class="icon"></ion-icon>
    <div class="title">{{ this.activationStatus.title }}</div>
  </button>
</template>

<script>
export default {
  props: {
    broker: {
      required: true,
    },
    activations: {
      required: true,
    },
  },
  data() {
    return {
      activationStatus: {
        title: "Loading",
        icon: "",
        disabled: true,
        focus: false,
      },
      activationEnabled: false,
    };
  },
  computed: {
    cssClasses() {
      return {
        button: true,
        disabled: this.activationStatus.disabled,
        enabled: !this.activationStatus.disabled,
        focus: this.activationStatus.focus,
      };
    },
  },
  methods: {
    async activationCreate() {
      if (this.activationEnabled) {
        const payload = {
          broker: this.broker.id,
          environment_type: this.$store.getters["environments/environmentType"],
        };
        await this.$store.dispatch("activations/activationCreate", payload);
        this.$store.dispatch("activations/activationList");
      }
    },
  },
  watch: {
    activations: {
      handler(activations) {
        if (!this.broker.is_available) {
          this.activationStatus = {
            title: "Unavailable",
            icon: "close-circle-outline",
            disabled: true,
            focus: false,
          };
        } else if (!this.broker.enabled_activations) {
          this.activationStatus = {
            title: "Coming Soon",
            icon: "hourglass-outline",
            disabled: true,
            focus: false,
          };
        } else {
          const brokerActivation = activations.filter(
            (item) => item.broker.id == this.broker.id
          );
          if (brokerActivation.length == 0) {
            this.activationEnabled = true;
            this.activationStatus = {
              title: "Activate",
              icon: "add-circle-outline",
              disabled: false,
              focus: false,
            };
          } else if (brokerActivation[0].is_active) {
            this.activationStatus = {
              title: "Active",
              icon: "checkmark-circle-outline",
              disabled: true,
              focus: true,
            };
          } else {
            this.activationStatus = {
              title: "Pending",
              icon: "alert-circle-outline",
              disabled: true,
              focus: true,
            };
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  gap: var(--spacing-4);
  align-items: center;
  justify-content: flex-start;
  background-color: var(--primary-grey-1);
  align-self: stretch;
  padding: var(--spacing-3) var(--spacing-4);
  border: 0.5px solid #ddd;
  border-radius: var(--border-radius-1);
  transition: all 0.3s ease;
  font-weight: 300;
}

.focus {
  /* color: var(--primary-dark-1); */
  /* font-weight: 500; */
  color: var(--primary-color);
  border: 0.5px solid var(--primary-color);
}

.button.enabled:hover {
  background-color: var(--primary-grey-4);
}

.button .icon {
  font-size: var(--font-size-3);
  flex-shrink: 0;
}

.title {
  /* font-weight: 300; */
  font-size: var(--font-size-3);
  white-space: nowrap;
}

.disabled {
  cursor: unset;
}
</style>
