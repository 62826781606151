<template>
  <!-- <teleport to="#app"> -->
  <dialog open :class="dialogClasses">
    <div class="inner">
      <ion-icon
        v-if="notification.success"
        class="success-icon"
        name="checkmark-circle"
      ></ion-icon>
      <ion-icon v-else class="failure-icon" name="close-circle"></ion-icon>
      <div>
        <p>{{ notification.message }}</p>
        <div v-if="hasValue" class="copy-box">
          <p class="copy-value">{{ notification.value }}</p>
          <ion-icon
            class="copy-icon"
            name="copy-outline"
            @click="copyToClipBoard"
          ></ion-icon>
          <div v-if="confirm" class="copy-confirmation">
            Copied to clipboard
          </div>
        </div>
      </div>
    </div>
  </dialog>
  <!-- </teleport> -->
</template>

<script>
export default {
  props: {
    notification: {
      required: true,
    },
  },
  data() {
    return { confirm: false };
  },
  created() {
    console.log("Notification", this.notification);
  },
  methods: {
    async copyToClipBoard() {
      navigator.clipboard.writeText(this.notification.value);
      this.confirm = true;
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(3000);
      this.confirm = false;
    },
  },
  computed: {
    // isSuccess() {
    //   return this.success;
    // },
    hasValue() {
      if (this.notification.value !== undefined) {
        return true;
      }
      return false;
    },
    dialogClasses() {
      return {
        outer: true,
        "outer-success": this.notification.success,
        "outer-failure": !this.notification.success,
      };
    },
  },
};
</script>

<style scoped>
.outer {
  min-height: var(--spacing-9);
  position: fixed;
  top: 0;
  left: 100%;
  transform: translate(-103%, 1rem);
  padding: var(--spacing-4) var(--spacing-5) var(--spacing-4) var(--spacing-4);
  border-radius: var(--border-radius-2);
  border: none;
  font-size: var(--font-size-5);
  z-index: 100;
  display: flex;
  align-items: center;
  /* background-color: var(--primary-light-4); */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* max-width: 30rem; */
  width: 30rem;
}

.outer-success {
  background-color: var(--primary-light-4);
  color: var(--primary-dark-4);
}

.outer-failure {
  background-color: var(--primary-red-light);
  color: var(--primary-red-dark);
}

.inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  color: var(--primary-black-1);
  font-size: var(--font-size-4);
  font-weight: 300;
  color: inherit;
}

.copy-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-3);
  margin-top: var(--spacing-3);
  color: var(--primary-black-1);
}

.copy-value {
  max-width: 10rem;
  overflow: hidden;
  /* font-weight: 300; */
  white-space: nowrap;
  font-size: var(--font-size-3);
}

.copy-confirmation {
  font-size: var(--font-size-2);
  font-weight: 600;
}

.success-icon,
.failure-icon,
.copy-icon {
  font-size: var(--font-size-6);
  flex-shrink: 0;
}

.success-icon {
  color: var(--primary-color);
}

.failure-icon {
  color: var(--primary-red-color);
}

.copy-icon:hover {
  --ionicon-stroke-width: 50px;
}
</style>
