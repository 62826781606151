export default {
  setLegalContent(state, payload) {
    console.log("HHHH", payload);
    state.legalContent = {
      ...payload,
      ...state.legalContent,
    };
    console.log("HEy", state.legalContent);
  },
};
