export default {
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  // setUser(state, payload) {
  //   state.isVerified = payload.is_verified;
  // },
  async addNotification(state, payload) {
    // state.notifications.add();
    console.log("ME PAYLOAD", payload);
    state.notifications.push(payload);
    console.log("STATE NOTIFS", state.notifications);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    await delay(10000);
    state.notifications = state.notifications.slice(0, -1);
    console.log("STATE NOTIFS", state.notifications);
  },
  setInfo(state, payload) {
    state.emailVerified = payload.email_verified;
    state.firstName = payload.first_name;
    state.lastName = payload.last_name;
    state.tier = payload.tier;
    state.email = payload.email;
  },
};
