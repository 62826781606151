<template>
  <landing-page>
    <landing-header></landing-header>
    <legal-template :content="content"></legal-template>
    <landing-footer></landing-footer>
  </landing-page>
</template>

<script>
import LandingHeader from "@/components/landing/LandingHeader.vue";
// import LandingPage from "@/components/landing/LandingPage.vue";
import LegalTemplate from "./LegalTemplate.vue";
import LandingFooter from "@/components/landing/LandingFooter.vue";

export default {
  // components: { LegalTemplate, LandingPage },
  components: { LandingHeader, LegalTemplate, LandingFooter },
  created() {
    this.$store.dispatch("files/fileRetrieve", { name: "privacy" });
  },
  computed: {
    content() {
      return (
        this.$store.getters["files/privacy"] ||
        "# Oops! Something is wrong. Please check back this page later on."
      );
    },
  },
};
</script>

<style></style>
